






































































































































































































































































































































import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import gsap from 'gsap'
import ArrowLink from '@/components/ArrowLink.vue'
import KeyPoints from '@/components/KeyPoints.vue'
import FeaturedReferences from '@/components/FeaturedReferences.vue'
import SolutionsOverview from '@/components/SolutionsOverview.vue'
import FeaturedNews from '@/components/FeaturedNews.vue'
import WebP from '@/components/WebP.vue'
import CustomEase from '@/inc/vendor/gsap/CustomEase'
import { motionOK } from '@/inc/utils/motionOK'
import LineSplitter from '@/components/animation/LineSplitter.vue'
import HorizontalLine from '@/components/animation/HorizontalLine.vue'
import Strech from '@/components/animation/Strech.vue'
import ZoomOutImg from '@/components/animation/ZoomOutImg.vue'

export default defineComponent({
  name: 'home',
  components: {
    ArrowLink,
    KeyPoints,
    FeaturedReferences,
    SolutionsOverview,
    FeaturedNews,
    WebP,
    LineSplitter,
    HorizontalLine,
    Strech,
    ZoomOutImg,
  },

  setup(_props, ctx) {
    const { content } = useGetters(['content'])
    const { onboardingOK } = useGetters(['onboardingOK'])
    const zoomSectionRef = ref()
    const logoRef = ref()
    const verticlaSwipeRef = ref()
    const heroRef = ref()
    const heroContentRef = ref()
    const heroVisualContainerRef = ref()
    const heroVisualContentRef = ref()

    const onboardingTL = gsap.timeline({
      defaults: { duration: 2, ease: 'power4' },
    })

    const playOnboardingAnimation = () => {
      ctx.root.$store.dispatch('flagOnboarding')

      const logoTopStart = logoRef.value.getBoundingClientRect().y

      gsap.set(heroRef.value, {
        height: '90vh',
      })

      gsap.set(logoRef.value, {
        y: logoTopStart - logoRef.value.getBoundingClientRect().y,
      })

      const logoPaths = logoRef.value.querySelectorAll('path') // First path is flame, second is text
      const headerElements = document.querySelectorAll('[data-header-element]')

      const customEase = CustomEase.create(
        'custom',
        'M0,0 C0.143,0 0.311,-0.012 0.468,0.02 0.602,0.048 0.632,0.068 0.674,0.562 0.714,1 0.704,1 1,1 '
      )

      const heroHeightEase = CustomEase.create(
        'custom',
        'M0,0 C0.07,0.698 0.092,1 1,1 '
      )

      const initialCurtainDelay = 0.5

      onboardingTL
        .addLabel('start')
        .addLabel('curtain', `start+=${initialCurtainDelay}`)
        .to(
          verticlaSwipeRef.value,
          {
            scaleY: 0,
            ease: customEase,
          },
          'curtain'
        )
        .to(
          logoPaths[0],
          {
            fill: '#e10600',
            ease: customEase,
          },

          'curtain'
        )
        .to(
          logoPaths[1],
          {
            fill: '#FFFFFF',
            ease: customEase,
          },
          'curtain'
        )
        .addLabel('hero', 'curtain+=1.25')
        .to(
          heroVisualContainerRef.value,
          {
            scaleY: 0.9,
            duration: 3,
            ease: heroHeightEase,
          },
          'hero'
        )
        .to(
          heroVisualContentRef.value,
          {
            scaleY: 1.1,
            duration: 3,
            ease: heroHeightEase,
          },
          'hero'
        )
        .to(
          logoRef.value,
          {
            y: 0,
            duration: 3,
            ease: heroHeightEase,
          },
          'hero'
        )
        .to(
          headerElements,
          {
            opacity: 1,
            y: 0,
            stagger: 0.1,
            duration: 1,
          },
          'curtain+=1.5'
        )

      // Reveal banner + animate in its components
      // Those elements might not exists if not content is encoded or if user has dismissed banner
      const bannerEl = document.querySelector('#js-banner')
      const bannerElements = document.querySelectorAll('[data-banner-element]')
      gsap.set(bannerElements, {
        opacity: 0,
        translateY: 15,
      })

      if (bannerEl && bannerEl.getAttribute('data-unread') === 'true') {
        onboardingTL.to(
          bannerEl,
          {
            clipPath: 'inset(0% 0% 0% 0%)',
            duration: 1,
          },
          'start+=2'
        )

        if (bannerElements) {
          onboardingTL.to(
            bannerElements,
            {
              opacity: 1,
              y: 0,
              stagger: 0.1,
              duration: 1,
            },
            'start+=2'
          )
        }
      }

      const cookieBarOuter = document.querySelector('.cookiebar.is-fullscreen')
      if (cookieBarOuter) {
        onboardingTL.fromTo(
          cookieBarOuter,
          {
            backgroundColor: 'rgba(0,0,0,0)',
          },
          {
            backgroundColor: 'rgba(0,0,0,.75)',
          },
          'start+=2'
        )
      }

      const cookieBarEl = document.querySelector('.cookiebar-controls')
      if (cookieBarEl) {
        onboardingTL.fromTo(
          cookieBarEl,
          {
            opacity: 0,
            y: 500,
          },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            clearProps: 'all',
          },
          'start+=2'
        )
      }
    }

    const skipOnboardingAnimation = () => {
      // FIXME: find a DRYier solution. If one of those final value changes, it should be replaced in multiple places

      // First path is flame, second is text
      const logoPaths = logoRef.value.querySelectorAll('path')
      const headerElements = document.querySelectorAll('[data-header-element]')
      const bannerEl = document.querySelector('#js-banner')
      const bannerElements = document.querySelectorAll('[data-banner-element]')

      gsap.set(verticlaSwipeRef.value, {
        scaleY: 0,
      })
      gsap.set(verticlaSwipeRef.value, {
        scaleY: 0,
      })
      gsap.set(logoPaths[0], {
        fill: '#e10600',
      })
      gsap.set(logoPaths[1], {
        fill: '#FFFFFF',
      })

      // TODO: Si on décide d'animer opacité via onboarding : décommenter
      // Si on décide d'animer au scroll, on supprime
      /* gsap.set(heroRef.value.querySelector('img'), {
        opacity: 0.6,
      }) */

      gsap.set(heroRef.value, {
        height: '90vh',
      })
      gsap.set(headerElements, {
        opacity: 1,
        y: 0,
      })
      if (bannerEl) {
        gsap.set(bannerEl, {
          clipPath: 'inset(0% 0% 0% 0%)',
        })
      }
      if (bannerElements) {
        gsap.set(bannerElements, {
          opacity: 1,
          y: 0,
        })
      }
      gsap.set(heroVisualContainerRef.value, {
        scaleY: 0.9,
      })
      gsap.set(heroVisualContentRef.value, {
        scaleY: 1.1,
      })
    }

    const introTitle = ref()
    const introBaseline = ref()
    const introHR = ref()
    const introContent = ref()
    const introAnimateIn = () => {
      const TL = gsap.timeline({
        defaults: {
          duration: 1,
          ease: 'power4.out',
        },
      })
      TL.add(introTitle.value.getTween(), 'start')
      TL.add(introBaseline.value.getTween(), 'start+=0.5')
      TL.add(introHR.value.getTween(), 'start+=0.5')
      TL.add(introContent.value.getTween(), 'start+=0.5')
    }

    const expertiseTitle = ref()
    const expertiseBaseline = ref()
    const expertiseHR = ref()
    const expertiseCTA = ref()
    const expertiseAnimateIn = () => {
      const TL = gsap.timeline({
        defaults: {
          duration: 1,
          ease: 'power4.out',
        },
      })

      TL.add(expertiseBaseline.value.getTween(), 'start')
      TL.add(expertiseHR.value.getTween(), 'start')
      TL.add(expertiseTitle.value.getTween(), 'start+=0.25')
      TL.add(expertiseCTA.value.getTween(), 'start+=0.5')
    }

    const onScroll = () => {
      if (
        onboardingTL.nextLabel() === 'curtain' ||
        onboardingTL.nextLabel() === 'hero'
      ) {
        onboardingTL.seek('hero')
      }

      removeScrollListener()
    }

    const removeScrollListener = () => {
      document.removeEventListener('scroll', onScroll)
    }

    onMounted(() => {
      if (heroRef.value) {
        const imgEl = heroRef.value.querySelector('img')
        if (imgEl && motionOK()) {
          gsap.to(imgEl, {
            scale: 1,
            opacity: 0.2,
            yPercent: 30,
            scrollTrigger: {
              trigger: heroVisualContainerRef.value,
              scrub: true,
              start: 'top top',
              end: 'bottom top',
            },
          })
        }
      }

      if (motionOK() && onboardingOK.value) {
        playOnboardingAnimation()

        document.addEventListener('scroll', onScroll)
      } else {
        skipOnboardingAnimation()
      }
    })

    onUnmounted(() => {
      removeScrollListener()
    })

    return {
      content,
      heroRef,
      heroContentRef,
      zoomSectionRef,
      logoRef,
      verticlaSwipeRef,
      heroVisualContainerRef,
      heroVisualContentRef,

      introTitle,
      introBaseline,
      introHR,
      introContent,
      introAnimateIn,

      expertiseTitle,
      expertiseBaseline,
      expertiseHR,
      expertiseCTA,
      expertiseAnimateIn,

      domain: process.env.VUE_APP_API_DOMAIN,
    }
  },
})
